var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { attrs: { xs12: "" } },
    [
      _c(
        "panel",
        {
          staticClass: "query-panel d-flex fill-height",
          attrs: { "fill-height": "" },
        },
        [
          _c("template", { slot: "header" }, [
            _vm._v("\n        Cluster selection\n    "),
          ]),
          _vm._v(" "),
          _c(
            "template",
            { slot: "content" },
            [
              _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  items: _vm.response,
                  options: _vm.options,
                  "server-items-length": _vm.total,
                  "footer-props": {
                    "items-per-page-options": [10, 20, 50, 100, -1],
                  },
                },
                on: {
                  "update:options": function ($event) {
                    _vm.options = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.structure",
                    fn: function (prop) {
                      return [
                        _c(
                          "div",
                          { staticStyle: { "text-align": "center" } },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "cluster",
                                    params: {
                                      cluster: prop.item.rep_accession,
                                    },
                                  },
                                  target: "_blank",
                                },
                              },
                              [
                                _c("img", {
                                  staticStyle: { height: "75px" },
                                  attrs: {
                                    src: _vm.getImage(prop.item.rep_accession),
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.rep_accession",
                    fn: function (prop) {
                      return [
                        _c(
                          "ExternalLinks",
                          { attrs: { accession: prop.value } },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  slot: "accession",
                                  to: {
                                    name: "cluster",
                                    params: { cluster: prop.value },
                                  },
                                  target: "_blank",
                                },
                                slot: "accession",
                              },
                              [_vm._v(_vm._s(prop.value))]
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                " +
                            _vm._s(prop.item.description) +
                            "\n            "
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.avg_plddt",
                    fn: function (prop) {
                      return [
                        _vm._v(
                          "\n                " +
                            _vm._s(prop.value.toFixed(2)) +
                            "\n            "
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.rep_plddt",
                    fn: function (prop) {
                      return [
                        _vm._v(
                          "\n                " +
                            _vm._s(prop.value.toFixed(2)) +
                            "\n            "
                        ),
                      ]
                    },
                  },
                  {
                    key: "header.lca_tax_id",
                    fn: function (ref) {
                      var header = ref.header
                      return [
                        _c("TaxonomyAutocomplete", {
                          attrs: {
                            urlFunction: function (_, b) {
                              return "/search/lca/" + b
                            },
                            disabled: _vm.taxAutocompleteDisabled,
                            options: _vm.requestOptions,
                          },
                          model: {
                            value: _vm.options.tax_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "tax_id", $$v)
                            },
                            expression: "options.tax_id",
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "item.lca_tax_id",
                    fn: function (prop) {
                      return [
                        _c("TaxSpan", { attrs: { taxonomy: prop.value } }),
                      ]
                    },
                  },
                  {
                    key: "header.is_dark",
                    fn: function (ref) {
                      var header = ref.header
                      return [
                        _c(
                          "v-menu",
                          {
                            attrs: {
                              "close-on-content-click": false,
                              "offset-y": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              outlined:
                                                _vm.options.is_dark != null,
                                              plain: "",
                                            },
                                          },
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(header.text) +
                                              "\n                        "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _vm._v(" "),
                            _c(
                              "v-card",
                              {
                                staticStyle: { padding: "2em", width: "250px" },
                              },
                              [
                                _c("h3", [_vm._v("Filter by")]),
                                _vm._v(" "),
                                _c(
                                  "v-chip-group",
                                  {
                                    attrs: { column: "" },
                                    model: {
                                      value: _vm.options.is_dark,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.options, "is_dark", $$v)
                                      },
                                      expression: "options.is_dark",
                                    },
                                  },
                                  [
                                    _c("IsDark", { attrs: { isDark: "0" } }),
                                    _vm._v(" "),
                                    _c("IsDark", { attrs: { isDark: "1" } }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.is_dark",
                    fn: function (prop) {
                      return [_c("IsDark", { attrs: { isDark: prop.value } })]
                    },
                  },
                  {
                    key: "header.avg_len",
                    fn: function (ref) {
                      var header = ref.header
                      return [
                        _c(
                          "v-menu",
                          {
                            attrs: {
                              "close-on-content-click": false,
                              "offset-y": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g({ attrs: { plain: "" } }, on),
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(header.text) +
                                              "\n                        "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _vm._v(" "),
                            _c("RangeSlider", {
                              attrs: { range: _vm.options.avg_length_range },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "header.avg_plddt",
                    fn: function (ref) {
                      var header = ref.header
                      return [
                        _c(
                          "v-menu",
                          {
                            attrs: {
                              "close-on-content-click": false,
                              "offset-y": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g({ attrs: { plain: "" } }, on),
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(header.text) +
                                              "\n                        "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _vm._v(" "),
                            _c("RangeSlider", {
                              attrs: { range: _vm.options.avg_plddt_range },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "header.n_mem",
                    fn: function (ref) {
                      var header = ref.header
                      return [
                        _c(
                          "v-menu",
                          {
                            attrs: {
                              "close-on-content-click": false,
                              "offset-y": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g({ attrs: { plain: "" } }, on),
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(header.text) +
                                              "\n                        "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _vm._v(" "),
                            _c("RangeSlider", {
                              attrs: { range: _vm.options.n_mem_range },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "header.rep_len",
                    fn: function (ref) {
                      var header = ref.header
                      return [
                        _c(
                          "v-menu",
                          {
                            attrs: {
                              "close-on-content-click": false,
                              "offset-y": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g({ attrs: { plain: "" } }, on),
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(header.text) +
                                              "\n                        "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _vm._v(" "),
                            _c("RangeSlider", {
                              attrs: { range: _vm.options.rep_length_range },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "header.rep_plddt",
                    fn: function (ref) {
                      var header = ref.header
                      return [
                        _c(
                          "v-menu",
                          {
                            attrs: {
                              "close-on-content-click": false,
                              "offset-y": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g({ attrs: { plain: "" } }, on),
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(header.text) +
                                              "\n                        "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _vm._v(" "),
                            _c("RangeSlider", {
                              attrs: { range: _vm.options.rep_plddt_range },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }