var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Panel",
    { staticStyle: { "margin-top": "1em" }, attrs: { collapsible: "" } },
    [
      _c("template", { slot: "header" }, [
        _vm._v("\n        Similar clusters\n    "),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "toolbar-extra" },
        [
          _c(
            "v-menu",
            {
              attrs: { "offset-y": "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function (ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g({ attrs: { plain: "" } }, on),
                        [
                          _c("v-icon", { staticClass: "mr-1" }, [
                            _vm._v(_vm._s(_vm.$MDI.Export)),
                          ]),
                          _vm._v(
                            "\n                    Export\n                "
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _vm._v(" "),
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        href:
                          _vm.$axios.defaults.baseURL +
                          "/cluster/" +
                          _vm.$route.params.cluster +
                          "/similars?format=accessions&" +
                          _vm.requestOptions.params.toString(),
                        target: "_blank",
                      },
                    },
                    [
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Accessions")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        href:
                          _vm.$axios.defaults.baseURL +
                          "/cluster/" +
                          _vm.$route.params.cluster +
                          "/similars?format=fasta&" +
                          _vm.requestOptions.params.toString(),
                        target: "_blank",
                      },
                    },
                    [
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("FASTA")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.$route.params.cluster
        ? _c(
            "template",
            { slot: "content" },
            [
              _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  items: _vm.entries,
                  options: _vm.options,
                  "server-items-length": _vm.totalEntries,
                  loading: _vm.loading,
                  "footer-props": {
                    "items-per-page-options": [10, 20, 50, 100, -1],
                  },
                },
                on: {
                  "update:options": function ($event) {
                    _vm.options = $event
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.rep_accession",
                      fn: function (prop) {
                        return [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "cluster",
                                  params: { cluster: prop.value },
                                },
                              },
                            },
                            [_vm._v(_vm._s(prop.value))]
                          ),
                          _c("br"),
                          _vm._v(
                            "\n            " +
                              _vm._s(prop.item.description) +
                              "\n        "
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.avg_len",
                      fn: function (prop) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(prop.value.toFixed(2)) +
                              "\n        "
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.avg_plddt",
                      fn: function (prop) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(prop.value.toFixed(2)) +
                              "\n        "
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.rep_plddt",
                      fn: function (prop) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(prop.value.toFixed(2)) +
                              "\n        "
                          ),
                        ]
                      },
                    },
                    {
                      key: "header.structure",
                      fn: function (ref) {
                        var header = ref.header
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(header.text) +
                              "\n            "
                          ),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "span",
                                          _vm._g({}, on),
                                          [
                                            _c("v-icon", _vm._g({}, on), [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$MDI.HelpCircleOutline
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "\n                   Click on a structure to superpose it on to the cluster representative in the structure viewer\n                "
                                ),
                              ]),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.structure",
                      fn: function (prop) {
                        return [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "ripple",
                                  rawName: "v-ripple",
                                  value: { class: "primary--text" },
                                  expression: "{ class: `primary--text` }",
                                },
                              ],
                              staticStyle: {
                                "text-align": "center",
                                cursor: "pointer",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit(
                                    "select",
                                    prop.item.rep_accession
                                  )
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticStyle: { height: "75px" },
                                attrs: {
                                  src: _vm.getImage(prop.item.rep_accession),
                                },
                              }),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "header.lca_tax_id",
                      fn: function (ref) {
                        var header = ref.header
                        return [
                          _c("TaxonomyAutocomplete", {
                            attrs: {
                              cluster: _vm.cluster,
                              urlFunction: function (a, b) {
                                return (
                                  "/cluster/" + a + "/similars/taxonomy/" + b
                                )
                              },
                              options: _vm.requestOptions,
                              disabled: _vm.taxAutocompleteDisabled,
                            },
                            model: {
                              value: _vm.options.tax_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.options, "tax_id", $$v)
                              },
                              expression: "options.tax_id",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "item.lca_tax_id",
                      fn: function (prop) {
                        return [
                          _c("TaxSpan", { attrs: { taxonomy: prop.value } }),
                        ]
                      },
                    },
                    {
                      key: "item.actions",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-chip",
                            {
                              attrs: {
                                title: "Search with Foldseek",
                                href:
                                  "https://search.foldseek.com/search?accession=" +
                                  item.rep_accession +
                                  "&source=AlphaFoldDB",
                              },
                            },
                            [
                              _c("v-img", {
                                attrs: {
                                  src: require("./assets/marv-foldseek-small.png"),
                                  "max-width": "16",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3766822245
                ),
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }