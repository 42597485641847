var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: { "close-on-content-click": false },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function (ref) {
              var on = ref.on
              return [
                _vm.$slots["accession"]
                  ? _vm._t("accession")
                  : [_vm._v(_vm._s(_vm.accession))],
                _vm._v(" "),
                _c(
                  "v-btn",
                  _vm._g(
                    { attrs: { plain: "", text: "", small: "", icon: "" } },
                    on
                  ),
                  [
                    _c("v-icon", { attrs: { small: "" } }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$MDI.OpenInNew) +
                          "\n            "
                      ),
                    ]),
                  ],
                  1
                ),
              ]
            },
          },
        ],
        null,
        true
      ),
    },
    [
      _vm._v(" "),
      _c(
        "v-list",
        [
          _c(
            "v-list-item",
            {
              attrs: {
                href: "https://www.uniprot.org/uniprotkb/" + _vm.accession,
                target: "_blank",
                rel: "noopener",
              },
            },
            [
              _c(
                "v-list-item-content",
                [_c("v-list-item-title", [_vm._v("UniProt")])],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-list-item",
            {
              attrs: {
                href: "https://uniprot3d.org/atlas/AFDB90v4/#" + _vm.accession,
                target: "_blank",
                rel: "noopener",
              },
            },
            [
              _c(
                "v-list-item-content",
                [_c("v-list-item-title", [_vm._v("UniProt3D Atlas")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }