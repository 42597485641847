var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticStyle: { margin: "1em" } },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "", md8: "" } },
        [
          _c(
            "panel",
            [
              _vm.response
                ? _c("template", { slot: "header" }, [
                    _vm._v(
                      "\n            Cluster: " +
                        _vm._s(
                          _vm.response
                            ? _vm.response.rep_accession
                            : "Loading..."
                        ) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.response && _vm.response.warning == true
                ? _c(
                    "template",
                    { slot: "toolbar-extra" },
                    [
                      _c("v-chip", { attrs: { color: "error" } }, [
                        _vm._v("Warning"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.response
                ? _c(
                    "template",
                    { slot: "content" },
                    [
                      _c("h3", [_vm._v("Representative summary")]),
                      _vm._v(" "),
                      _c("dl", { staticClass: "dl-3" }, [
                        _c("div", [
                          _c("dt", [
                            _vm._v(
                              "\n                    Accession\n                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "dd",
                            [
                              _c("ExternalLinks", {
                                attrs: {
                                  accession: _vm.response.rep_accession,
                                },
                              }),
                              _c("br"),
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.response.description) +
                                  "\n                "
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("dt", [
                            _vm._v(
                              "\n                    Length\n                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("dd", [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.response.rep_len) +
                                " aa\n                "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("dt", [
                            _vm._v(
                              "\n                    pLDDT\n                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("dd", [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.response.rep_plddt.toFixed(2)) +
                                "\n                "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "grid-area": "2 / 1 / 3 / 4" } },
                          [
                            _c("dt", [
                              _vm._v(
                                "\n                    Taxonomy\n                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "dd",
                              [
                                _vm._l(
                                  _vm.response.rep_lineage,
                                  function (taxonomy, index) {
                                    return [
                                      _c("TaxSpan", {
                                        key: taxonomy.id,
                                        attrs: { taxonomy: taxonomy },
                                      }),
                                      index <
                                      _vm.response.rep_lineage.length - 1
                                        ? [_vm._v(" » ")]
                                        : _vm._e(),
                                    ]
                                  }
                                ),
                              ],
                              2
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("v-divider", {
                        staticStyle: { "margin-top": "0.5em" },
                      }),
                      _vm._v(" "),
                      _c(
                        "h3",
                        { staticStyle: { "margin-top": "1em" } },
                        [
                          _vm._v(
                            "\n                    Cluster summary\n                    "
                          ),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "span",
                                          _vm._g({}, on),
                                          [
                                            _c("v-icon", _vm._g({}, on), [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$MDI.HelpCircleOutline
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1692497106
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "\n                            These values are computed among the members with the "
                                ),
                                _c("strong", [_vm._v("clustered step")]),
                                _vm._v(
                                  " AFDB/Foldseek.\n                        "
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("dl", { staticClass: "dl-4" }, [
                        _c("div", [
                          _c("dt", [
                            _vm._v(
                              "\n                    Number of members\n                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("dd", [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.response.n_mem) +
                                "\n                "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("dt", [
                            _vm._v(
                              "\n                    Dark cluster\n                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("dd", [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.response.is_dark ? "yes" : "no") +
                                "\n                "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("dt", [
                            _vm._v(
                              "\n                    Average length\n                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("dd", [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.response.avg_len.toFixed(2)) +
                                " aa\n                "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("dt", [
                            _vm._v(
                              "\n                    Average pLDDT\n                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("dd", [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.response.avg_plddt.toFixed(2)) +
                                "\n                "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "grid-area": "2 / 1 / 3 / 5" } },
                          [
                            _c("dt", [
                              _vm._v(
                                "\n                    Lowest common ancestor and lineage\n                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "dd",
                              [
                                _vm._l(
                                  _vm.response.lineage,
                                  function (taxonomy, index) {
                                    return [
                                      _c("TaxSpan", {
                                        key: taxonomy.id,
                                        attrs: { taxonomy: taxonomy },
                                      }),
                                      index < _vm.response.lineage.length - 1
                                        ? [_vm._v(" » ")]
                                        : _vm._e(),
                                    ]
                                  }
                                ),
                              ],
                              2
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.response && _vm.response.warning == true
                        ? [
                            _c("v-divider", {
                              staticStyle: { "margin-top": "0.5em" },
                            }),
                            _vm._v(" "),
                            _c(
                              "h3",
                              {
                                staticStyle: {
                                  "margin-top": "1em",
                                  color: "#F44336",
                                  "text-decoration": "underline",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                    Warning!\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                "\n                    This cluster was wrongly merged with another cluster. We are working on a fix.\n                "
                              ),
                            ]),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-flex",
        { attrs: { xs12: "", md4: "" } },
        [
          _c(
            "Panel",
            { staticClass: "repr-structure" },
            [
              _c("template", { slot: "header" }, [
                _vm._v("\n            Representative structure\n        "),
              ]),
              _vm._v(" "),
              _vm.response
                ? _c(
                    "template",
                    { slot: "content" },
                    [
                      _vm.$route.params.cluster
                        ? _c("StructureViewer", {
                            attrs: {
                              cluster: _vm.$route.params.cluster,
                              second: _vm.second,
                              bgColorDark: "#2e2e2e",
                            },
                            on: {
                              reset: function ($event) {
                                _vm.second = ""
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v("\np    "),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c("Members", {
            attrs: { cluster: _vm.$route.params.cluster },
            on: {
              select: function (accession) {
                return (_vm.second = accession)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c("Similars", {
            attrs: { cluster: _vm.$route.params.cluster },
            on: {
              select: function (accession) {
                return (_vm.second = accession)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }