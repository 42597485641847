var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      attrs: {
        "grid-list-md": "",
        fluid: "",
        "px-0": "",
        "py-0": "",
        id: "search-container",
      },
    },
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "", "pa-0": "" } },
            [
              _c(
                "v-parallax",
                {
                  attrs: {
                    height: _vm.windowHeight,
                    src: require("./assets/bg-bg.jpg"),
                    dark: "",
                  },
                },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "marv-bg-fg",
                      attrs: { align: "center", justify: "center" },
                    },
                    [
                      _c(
                        "v-col",
                        { staticClass: "text-center", attrs: { cols: "12" } },
                        [
                          _c(
                            "h1",
                            { staticClass: "text-h4 font-weight-thin mb-4" },
                            [
                              _vm._v(
                                "\n                            AlphaFold Clusters\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("h4", { staticClass: "subheading" }, [
                            _vm._v(
                              "\n                            Investigate the "
                            ),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "https://foldseek.com",
                                  target: "_blank",
                                  rel: "noopener",
                                },
                              },
                              [_vm._v("Foldseek")]
                            ),
                            _vm._v(" clustered "),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "https://alphafold.ebi.ac.uk",
                                  target: "_blank",
                                  rel: "noopener",
                                },
                              },
                              [_vm._v("AlphaFold database")]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "v-tabs",
                            {
                              staticClass: "search-select",
                              attrs: {
                                "slider-size": "1",
                                centered: "",
                                "background-color": "transparent",
                                dark: "",
                              },
                              model: {
                                value: _vm.tab,
                                callback: function ($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab",
                              },
                            },
                            [
                              _c("v-tab", [_vm._v("UniProt")]),
                              _vm._v(" "),
                              _c("v-tab", [_vm._v("Gene Ontology")]),
                              _vm._v(" "),
                              _c("v-tab", [_vm._v("Taxonomy")]),
                              _vm._v(" "),
                              _c("v-tab", [_vm._v("Structure")]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tabs-items",
                            {
                              staticStyle: { padding: "1em" },
                              model: {
                                value: _vm.tab,
                                callback: function ($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab",
                              },
                            },
                            [
                              _c(
                                "v-tab-item",
                                [
                                  _c("v-text-field", {
                                    staticStyle: {
                                      "max-width": "400px",
                                      margin: "0 auto",
                                    },
                                    attrs: {
                                      outlined: "",
                                      label: "UniProt accession",
                                      "append-icon": _vm.inSearch
                                        ? _vm.$MDI.ProgressWrench
                                        : _vm.$MDI.Magnify,
                                      disabled: _vm.inSearch,
                                      error: _vm.error != null,
                                      "error-messages": _vm.error
                                        ? _vm.error
                                        : [],
                                      dark: "",
                                    },
                                    on: {
                                      "click:append": _vm.search,
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.search.apply(null, arguments)
                                      },
                                      change: function ($event) {
                                        _vm.selectedExample = null
                                      },
                                      keydown: function ($event) {
                                        _vm.error = null
                                      },
                                    },
                                    model: {
                                      value: _vm.query,
                                      callback: function ($$v) {
                                        _vm.query = $$v
                                      },
                                      expression: "query",
                                    },
                                  }),
                                  _vm._v(" "),
                                  [
                                    _c("h2", { staticClass: "text-h6 mb-2" }, [
                                      _vm._v(
                                        "\n                                        Examples\n                                    "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "v-chip-group",
                                      {
                                        staticStyle: {
                                          "max-width": "400px",
                                          margin: "0 auto",
                                        },
                                        attrs: { column: "", dark: "" },
                                        model: {
                                          value: _vm.selectedExample,
                                          callback: function ($$v) {
                                            _vm.selectedExample = $$v
                                          },
                                          expression: "selectedExample",
                                        },
                                      },
                                      _vm._l(_vm.examples, function (item) {
                                        return _c(
                                          "v-chip",
                                          {
                                            key: item.id,
                                            attrs: { outlined: "" },
                                            on: {
                                              click: function ($event) {
                                                _vm.query = item.id
                                              },
                                            },
                                          },
                                          [
                                            _c("b", [_vm._v(_vm._s(item.id))]),
                                            _vm._v(
                                              "   " +
                                                _vm._s(item.desc) +
                                                "\n                                        "
                                            ),
                                          ]
                                        )
                                      }),
                                      1
                                    ),
                                  ],
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c(
                                "v-tab-item",
                                [
                                  _c("GoAutocomplete", {
                                    attrs: {
                                      "append-icon": _vm.inSearch
                                        ? _vm.$MDI.ProgressWrench
                                        : _vm.$MDI.Magnify,
                                      disabled: _vm.inSearch,
                                      error: _vm.error != null,
                                      "error-messages": _vm.error
                                        ? _vm.error
                                        : [],
                                    },
                                    on: {
                                      "click:append": _vm.searchGo,
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.searchGo.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                      change: function ($event) {
                                        _vm.selectedExample = null
                                      },
                                      keydown: function ($event) {
                                        _vm.error = null
                                      },
                                    },
                                    model: {
                                      value: _vm.queryGo,
                                      callback: function ($$v) {
                                        _vm.queryGo = $$v
                                      },
                                      expression: "queryGo",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "v-radio-group",
                                    {
                                      staticStyle: {
                                        "max-width": "400px",
                                        margin: "0 auto",
                                      },
                                      attrs: { inline: "" },
                                      model: {
                                        value: _vm.goSearchType,
                                        callback: function ($$v) {
                                          _vm.goSearchType = $$v
                                        },
                                        expression: "goSearchType",
                                      },
                                    },
                                    [
                                      _c("v-radio", {
                                        attrs: {
                                          name: "goSearchType",
                                          label: "Include lower GO lineage",
                                          value: "lower",
                                          dark: "",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("v-radio", {
                                        attrs: {
                                          name: "goSearchType",
                                          label: "Exact GO term",
                                          value: "exact",
                                          dark: "",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-tab-item",
                                [
                                  _c("TaxonomyNcbiSearch", {
                                    attrs: {
                                      "append-icon": _vm.inSearch
                                        ? _vm.$MDI.ProgressWrench
                                        : _vm.$MDI.Magnify,
                                      value: _vm.queryLCA
                                        ? _vm.queryLCA.text
                                        : "",
                                    },
                                    on: {
                                      "click:append": _vm.searchLCA,
                                      input: _vm.searchLCA,
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.searchLCA.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.queryLCA,
                                      callback: function ($$v) {
                                        _vm.queryLCA = $$v
                                      },
                                      expression: "queryLCA",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "v-radio-group",
                                    {
                                      staticStyle: {
                                        "max-width": "400px",
                                        margin: "0 auto",
                                      },
                                      attrs: { inline: "" },
                                      model: {
                                        value: _vm.lcaSearchType,
                                        callback: function ($$v) {
                                          _vm.lcaSearchType = $$v
                                        },
                                        expression: "lcaSearchType",
                                      },
                                    },
                                    [
                                      _c("v-radio", {
                                        attrs: {
                                          name: "lcaSearchType",
                                          label: "Include lower LCA lineage",
                                          value: "lower",
                                          dark: "",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("v-radio", {
                                        attrs: {
                                          name: "lcaSearchType",
                                          label: "Exact LCA identifier",
                                          value: "exact",
                                          dark: "",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-tab-item",
                                [
                                  _c("FoldseekSearchButton", {
                                    attrs: { dark: "" },
                                    on: {
                                      response: function ($event) {
                                        return _vm.searchFoldseek($event)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.tab == 1
            ? _c("GoSearchResult", {
                on: {
                  total: function ($event) {
                    _vm.small = $event > 0
                    _vm.inSearch = false
                  },
                },
              })
            : _vm.tab == 2
            ? _c("LCASearchResult", {
                on: {
                  total: function ($event) {
                    _vm.small = $event > 0
                    _vm.inSearch = false
                  },
                },
              })
            : _vm.tab == 3
            ? _c("FoldseekSearchResult", {
                on: {
                  total: function ($event) {
                    _vm.small = $event > 0
                    _vm.inSearch = false
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-flex",
            [
              _c(
                "v-card",
                { attrs: { rounded: "0" } },
                [
                  _c(
                    "v-flex",
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass: "pb-0 mb-0",
                          attrs: { "primary-title": "" },
                        },
                        [
                          _c("div", { staticClass: "text-h5 mb-0" }, [
                            _vm._v("Reference"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-title",
                        {
                          staticClass: "pt-0 mt-0",
                          attrs: { "primary-title": "" },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "text-subtitle-1 mb-0",
                              staticStyle: { "word-break": "break-word" },
                            },
                            [
                              _vm._v(
                                "\n                        Barrio-Hernandez I, Yeo J, Jänes J, Mirdita M, Gilchrist CLM, Wein T, Varadi M, Velankar S, Beltrao P, Steinegger M. \n                        "
                              ),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "https://nature.com/articles/s41586-023-06510-w",
                                    target: "_blank",
                                    rel: "noopener",
                                  },
                                },
                                [
                                  _vm._v(
                                    "Clustering predicted structures at the scale of the known protein universe."
                                  ),
                                ]
                              ),
                              _vm._v(
                                "\n                        Nature, 2023.\n                    "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("p", { staticClass: "text-subtitle-1 mb-0 collab" }, [
                _vm._v(
                  "\n                AFDB Clusters is a collaboration between\n                "
                ),
                _c("a", { attrs: { href: "https://en.snu.ac.kr/" } }, [
                  _vm._v("Seoul National University"),
                ]),
                _vm._v(", the\n                "),
                _c("a", { attrs: { href: "https://www.ebi.ac.uk/" } }, [
                  _vm._v("European Bioinformatics Institute"),
                ]),
                _vm._v(", "),
                _c("br"),
                _vm._v("and the\n                "),
                _c("a", { attrs: { href: "https://www.sib.swiss/" } }, [
                  _vm._v("Swiss Institute of Bioinformatics"),
                ]),
                _vm._v(".\n            "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    "text-align": "center",
                    "padding-top": "12px",
                    "padding-bottom": "40px",
                  },
                },
                [
                  _c(
                    "a",
                    {
                      staticStyle: { margin: "12px" },
                      attrs: {
                        rel: "external noopener",
                        target: "_blank",
                        href: "https://en.snu.ac.kr/",
                        height: "128",
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "logos",
                        attrs: {
                          src: require("./assets/snu_logo_opt.svg"),
                          height: "64",
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticStyle: { margin: "12px" },
                      attrs: {
                        rel: "external noopener",
                        target: "_blank",
                        href: "https://www.ebi.ac.uk/",
                        height: "128",
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "logos",
                        attrs: {
                          src: require("./assets/embl_logo.svg"),
                          height: "64",
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticStyle: { margin: "12px" },
                      attrs: {
                        rel: "external noopener",
                        target: "_blank",
                        href: "https://www.sib.swiss/",
                        height: "128",
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "logos",
                        attrs: {
                          src: require("./assets/logo_sib.svg"),
                          height: "64",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }