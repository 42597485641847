var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Panel",
    { staticStyle: { "margin-top": "1em" }, attrs: { collapsible: "" } },
    [
      _c("template", { slot: "header" }, [
        _vm._v("\n        Cluster members\n    "),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "toolbar-extra" },
        [
          _c(
            "v-menu",
            {
              attrs: { "offset-y": "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function (ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g({ attrs: { plain: "" } }, on),
                        [
                          _c("v-icon", { staticClass: "mr-1" }, [
                            _vm._v(_vm._s(_vm.$MDI.Export)),
                          ]),
                          _vm._v(
                            "\n                    Export\n                "
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _vm._v(" "),
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        href:
                          _vm.$axios.defaults.baseURL +
                          "/cluster/" +
                          _vm.$route.params.cluster +
                          "/members?format=accessions&" +
                          _vm.requestOptions.params.toString(),
                        target: "_blank",
                      },
                    },
                    [
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Accessions")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        href:
                          _vm.$axios.defaults.baseURL +
                          "/cluster/" +
                          _vm.$route.params.cluster +
                          "/members?format=fasta&" +
                          _vm.requestOptions.params.toString(),
                        target: "_blank",
                      },
                    },
                    [
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("FASTA")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.$route.params.cluster
        ? _c(
            "template",
            { slot: "content" },
            [
              _c("Sankey", {
                attrs: { cluster: _vm.cluster, type: "members" },
                on: { select: _vm.sankeySelect },
              }),
              _vm._v(" "),
              _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  items: _vm.members,
                  options: _vm.options,
                  "server-items-length": _vm.totalMembers,
                  loading: _vm.loading,
                  "footer-props": {
                    "items-per-page-options": [10, 20, 50, 100, -1],
                  },
                },
                on: {
                  "update:options": function ($event) {
                    _vm.options = $event
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.accession",
                      fn: function (prop) {
                        return [
                          _c("ExternalLinks", {
                            attrs: { accession: prop.value },
                          }),
                          _c("br"),
                          _vm._v(
                            "\n            " +
                              _vm._s(prop.item.description) +
                              "\n        "
                          ),
                        ]
                      },
                    },
                    {
                      key: "header.structure",
                      fn: function (ref) {
                        var header = ref.header
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(header.text) +
                              "\n            "
                          ),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "span",
                                          _vm._g({}, on),
                                          [
                                            _c("v-icon", _vm._g({}, on), [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$MDI.HelpCircleOutline
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "\n                   Click on a structure to superpose it on to the cluster representative in the structure viewer\n                "
                                ),
                              ]),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.structure",
                      fn: function (prop) {
                        return [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "ripple",
                                  rawName: "v-ripple",
                                  value: { class: "primary--text" },
                                  expression: "{ class: `primary--text` }",
                                },
                              ],
                              staticStyle: {
                                "text-align": "center",
                                cursor: "pointer",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit(
                                    "select",
                                    prop.item.accession
                                  )
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticStyle: { height: "75px" },
                                attrs: {
                                  src: _vm.getImage(prop.item.accession),
                                },
                              }),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.flag",
                      fn: function (prop) {
                        return [_c("Fragment", { attrs: { flag: prop.value } })]
                      },
                    },
                    {
                      key: "header.flag",
                      fn: function (ref) {
                        var header = ref.header
                        return [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                "offset-y": "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              attrs: {
                                                outlined:
                                                  _vm.options.flagFilter !=
                                                  null,
                                              },
                                            },
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(header.text) +
                                                " \n                        "
                                            ),
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function (ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "span",
                                                            _vm._g({}, on),
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g({}, on),
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$MDI
                                                                        .HelpCircleOutline
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _vm._v(" "),
                                                _c("span", [
                                                  _c("img", {
                                                    attrs: {
                                                      width: "600",
                                                      src: require("./assets/cluster_step.jpg"),
                                                    },
                                                  }),
                                                  _c("br"),
                                                  _vm._v(
                                                    "\n                                AFDB/Foldseek: Clustered with structural similarity"
                                                  ),
                                                  _c("br"),
                                                  _vm._v(
                                                    "\n                                AFDB50/Mmseqs: Clustered at sequence identity 50%"
                                                  ),
                                                  _c("br"),
                                                  _vm._v(
                                                    "\n                                Fragment: Removed fragments among AFDB50"
                                                  ),
                                                  _c("br"),
                                                  _vm._v(
                                                    "\n                                Singleton: Removed singletons after fragment removal\n                            "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "v-card",
                                {
                                  staticStyle: {
                                    padding: "2em",
                                    width: "250px",
                                  },
                                },
                                [
                                  _c("h3", [_vm._v("Filter by")]),
                                  _vm._v(" "),
                                  _c(
                                    "v-chip-group",
                                    {
                                      attrs: { column: "" },
                                      model: {
                                        value: _vm.options.flagFilter,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.options,
                                            "flagFilter",
                                            $$v
                                          )
                                        },
                                        expression: "options.flagFilter",
                                      },
                                    },
                                    [
                                      _c("Fragment", { attrs: { flag: 1 } }),
                                      _vm._v(" "),
                                      _c("Fragment", { attrs: { flag: 2 } }),
                                      _vm._v(" "),
                                      _c("Fragment", { attrs: { flag: 3 } }),
                                      _vm._v(" "),
                                      _c("Fragment", { attrs: { flag: 4 } }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "header.tax_id",
                      fn: function (ref) {
                        var header = ref.header
                        return [
                          _c("TaxonomyAutocomplete", {
                            attrs: {
                              cluster: _vm.cluster,
                              urlFunction: function (a, b) {
                                return (
                                  "/cluster/" + a + "/members/taxonomy/" + b
                                )
                              },
                              options: _vm.requestOptions,
                              disabled: _vm.taxAutocompleteDisabled,
                            },
                            model: {
                              value: _vm.options.tax_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.options, "tax_id", $$v)
                              },
                              expression: "options.tax_id",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "item.tax_id",
                      fn: function (prop) {
                        return [
                          _c("TaxSpan", { attrs: { taxonomy: prop.value } }),
                        ]
                      },
                    },
                    {
                      key: "item.actions",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-chip",
                            {
                              attrs: {
                                title: "Search with Foldseek",
                                href:
                                  "https://search.foldseek.com/search?accession=" +
                                  item.accession +
                                  "&source=AlphaFoldDB",
                                target: "_blank",
                              },
                            },
                            [
                              _c("v-img", {
                                attrs: {
                                  src: require("./assets/marv-foldseek-small.png"),
                                  "max-width": "16",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2165576131
                ),
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }